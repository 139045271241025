.footer{
    margin-top: 1rem;
    background-color: #eeeeee;
    left: 0;
    width: 100%;
  }

.txt-branco{
    color: #2f2971;
}

.coluna-social{
    margin: 0px;
}
.textos{
    margin: 0px;
}