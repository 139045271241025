.carousel-caption{
    background-color: rgba(20 , 20, 20, 0.4);
}

#img-titulo{
    
}
#txt-resumocurso{
    text-align: justify;
    font-size: larger;
}

#curso-titulo{
    font-size: 40px;
    font-weight: 600;
    background-image: radial-gradient(circle,#282774, #02933f);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  #col-curso-texto{
    border-width: 6px;
    border-top-right-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: #24135a;
  }
  #col-img{
    float: right !important;
    padding-right: 0px;
  }