.navbar{
    background-color: #FFEB5A;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    padding: 0px !important;
    /*background-image: url('../../image/padrao.png');
    background-size: 400px;*/
}
.logo-img{
    height: 40px;
}

.login-bt{
    margin-right: 0px;
}

.nav-link{
    color: #2f2971;
    /*background-color: royalblue;*/
    border-radius: 10px;
    transition: 0.5s;
}
.nav-link-drop{
    color: white;
    align-self: center !important;
}
.nav-link:hover{
    color: #27a7a6;
    /*background-color: royalblue;*/
    border-radius: 10px;
}
.nav-bt-menu{
    color: white;
    /*background-color: royalblue;*/
    border-radius: 10px;
    transition: 0.5s;
}

.nav-bt-menu:hover{
    color: #27a7a6;
    /*background-color: royalblue;*/
    border-radius: 10px;
}


.ico-togle{
    color: #2f2971;
}
.navbar-nav > li >.drop-menu{
    background-color: #2f2971;
    border-radius: 15px;
    color: white;
    
}
.dropdown-menu{
    background-color: #2f2971;
    border-radius: 15px;
    color: white;
    
}
.button-btx{
    color: white;
}

.button-nav{
    color: white !important;
    align-self: center !important;
    background-color: #2f2971;
}

.login-icon{
    color: #2f2971;
}
.logged-icon{
    height: 40px;
}
.linkloginaviso{
    cursor: pointer;
}

.alert-primary {
    color: #0e4c8f;
    background-color: #5AA9E6;
    border-color: #b6d4fe;
}