.card-img-top{
    max-width: 100% !important;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.divisao{
    border-width: 2px;
    border-color:#74b3be;
}
.justificado{
    text-align: justify;
    padding-left: 8px;
    padding-right: 8px;
}
.cardnoticia{
    border-width: 3px;
    border-radius: 15px;
    border-color: #24135a;
}