.carousel-caption{
    background-color: rgba(20 , 20, 20, 0.4);
}

#img-titulo{
    max-height: 500px;
}
#txt-resumonoticia{
    text-align: justify;
    font-size: larger;
}

#noticia-titulo{
    font-size: 40px;
    font-weight: 600;
    background-image: radial-gradient(circle,#282774, #02933f);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  #col-noticia-texto{
    border-width: 6px;
    border-top-right-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: #24135a;
    min-height: 500px;
  }
  #col-img{
    float: right !important;
    padding-right: 0px;
    border-width: 6px;
    border-right-width: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-color: #24135a;
  }

  @media screen and (max-width: 766px) {
    #col-noticia-texto{
      border-width: 6px;
      border-top-right-radius: 0px;
      border-top-width: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-color: #24135a;
      min-height: 500px;
    }

    #col-img{
      float: right !important;
      padding-right: 0px;
      border-width: 6px;
      border-right-width: 6px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-color: #24135a;
    }
  }