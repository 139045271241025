#logo{
    width: 120px;
    padding: 10px;
}

#ladoesquerdo{
    background-color: #7CDE86;
}
#ladodireito{
    background-color: #FFF833;
}

#ABEn-id{
    color: #282774;
    font-weight: 900;
}
#Ser-id{
    color: #02933f;
    font-weight: 900;
}
#gipe-id{
    color: #f7c402;
    font-weight: 900;
}
#txt-quemsomos{
    text-align: justify;
    font-size: larger;
}

#noticias-titulo{
    font-size: 70px;
    font-weight: 600;
    background-image: radial-gradient(circle,#282774, #02933f);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }

  #cursos-titulo{
    font-size: 70px;
    font-weight: 600;
    background-image: radial-gradient(circle,#282774, #02933f);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }

  #divisao-hr {
    opacity: 1 !important;
    height: 10px;
    color: #24135a;
  }
  #linha-noticias{
    display: block;
    
  }