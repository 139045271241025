.container2{
    height: 100vh;
    width: 100vw;
    background-color: aqua;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-pessoa2{
    max-width: 100%;
    height: 100%;
    overflow: hidden;
}