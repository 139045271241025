#img-titulo-curso2{
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;
}
#col-curso-texto4{
    border-width: 4px;
    border-style: solid;
    border-color: #24135a;
    border-radius: 0px;
    border-bottom-right-radius: 20px;
    border-left-width: 0px;

}
#col-img-curso2{
    
    display: flex;
    
}

#curso-titulo{
    font-size: 24px;
    font-weight: 600;
    background-image: radial-gradient(circle,#282774, #02933f);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
  #txt-resumocurso{
    text-align: justify;
    font-size: medium;
}
#row-cursos2{
    border-top-left-radius: 20px;
    border-width: 4px;
   
    border-style: solid;
    border-color: #24135a;
    
    border-bottom-right-radius: 20px;
    
    
}

@media screen and (max-width: 766px) {
    #col-curso-texto2{
      border-width: 6px;
      border-top-right-radius: 0px;
      border-top-width: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-width: 0px;
      border-color: #24135a;
    }

    #col-img-curso2{
      float: right !important;
      padding-right: 0px;
      border-width: 6px;
      border-right-width: 6px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-color: #24135a;
      border-bottom-width: 0px;
    }
    #curso-titulo{
        margin-top: 12px;
    }
  }