
.troca-senha {
    width: 100%;
    padding: 1%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: white;
    height: 100vh;
    background-size: 400px;
}
.container{
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
}

.container-logo{
    background-color: #27a7a6;
    background-size: 400px;
}
.logo-anjus{
    height: 200px;
    background-color: #27a7a6;
    background-size: 400px;
}
.btgeral{
    color: #ffffff;
    background-color: #2f2971;
    border-color: #2f2971;
}