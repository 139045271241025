.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bt-roxo{
    background-color: #282774;
    border: none;
    border-radius: 8px;
    transition: 0.4s;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding: 4px;
    padding-inline: 12px;
}

.bt-roxo:hover{
    background-color: #fedb00;
}
.App-link {
  color: #61dafb;
}
.btn-primary{
  background-color: #fedb00;
  border-color: #fedb00;
}
.nolink{
  color: #24135a;
  text-decoration-line: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
