.cardassociado{
    border-width: 1px;
    border-radius: 2px;
    border-color: #24135a;

}
#coluna-lista{
    border: 12px;
    margin-top: auto;
    margin-bottom: auto;
}
#coluna-telefona-lista-associados{
    margin-top: auto;
    margin-bottom: auto;
   
}
#email-lista-associados{
    word-wrap: break-word !important;
}
#telefone-lista-associado{
    word-wrap: break-word !important;
}
#col-email-associado{

}