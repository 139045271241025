.cardcurso{
    border-width: 6px;
    border-radius: 30px;
    border-color: #24135a;
}
#card-img-top-curso{
    max-height: 350px;
    width: auto;
    align-self: flex-start;
}
#col-img-top-curso{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}