.azulclaro{
    color: #C1E3F5;
}
.azulescuro{
    color: #38ABC8;
}
.verdeanjus{
    color: #3CB4AD;
}
.roxoanjus{
    color: #27297C;
}